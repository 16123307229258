import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

// 引入高德地图
import AMap from 'vue-amap';
 
Vue.use(AMap);

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
// Vue.use(router);
// // 引入二维码
// import QRCode from "qrcode";
// Vue.use(QRCode)
 
// 初始化vue-amap
AMap.initAMapApiLoader({
  // 高德的key
  key: '9ca3f0c83bb72f2d86ddcd883cd64c5b',
  // 插件集合
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.4'
});

// router.beforeEach((to, from, next) => {    
//   // chrome
//   document.body.scrollTop = 0
//   // firefox
//   document.documentElement.scrollTop = 0
//   // safari
//   // window.pageYOffset = 0
//   next()
// })


//或者下面代码：

// 跳转后返回顶部
router.afterEach((to,from,next) => {
    window.scrollTo(0,0);
})


Vue.use(ElementUI);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");