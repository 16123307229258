<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import rem from "@/assets/rem.js";
export default {
  name: "app",
  data() {
    return {
    }
  },
  
  
  // created() {
  //   new DevicePixelRatio().init();
  // },
  components: {
    rem,
  },
};
</script>
<style lang="less">
@media only screen and (min-width: 320px) {
 html {
 font-size: 13.6533px !important;
 }
}
@media only screen and (min-width: 360px) {
 html {
 font-size: 15.36px !important;
 }
}
@media only screen and (min-width: 375px) {
 html {
 font-size: 16px !important;
 }
}
@media only screen and (min-width: 400px) {
 html {
 font-size: 17.0667px !important;
 }
}
@media only screen and (min-width: 414px) {
 html {
 font-size: 17.664px !important;
 }
}
</style>
