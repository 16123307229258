<template>
  <div class="viewdone_div">
    <!-- 导航栏 -->
    <div class="nav" :style="{ width: screenWidth + 'px' }">
      <img
        src="../assets/viewdone/logo.png"
        alt=""
        v-if="ChineseEn == 1 || ChineseEn == null"
        v-show="!isMobile"
      />
      <img
        src="../assets/EN/nav.png"
        alt=""
        v-if="ChineseEn == 1 || ChineseEn == null"
        v-show="isMobile"
      />
      <img src="../assets/EN/nav.png" alt="" v-else />
      <div class="right">
        <el-menu
          :default-active="$router.currentRoute.path"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          :style="{ display: isMenu1 }"
          style="
            margin-right: 7px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <el-menu-item index="/index"
            ><router-link
              to="/index"
              v-if="ChineseEn == 1 || ChineseEn == null"
              class="menu1"
              >首页</router-link
            >
            <router-link to="/index" v-else>index</router-link>
          </el-menu-item>
          <el-submenu
            index="2"
            :class="ChineseEn == 1 || ChineseEn == null ? 'menu1' : ''"
          >
            <template slot="title" v-if="ChineseEn == 1 || ChineseEn == null"
              >产品展示</template
            >
            <template slot="title" v-else>Products</template>
            <el-menu-item index="/A6">
              <router-link
                to="/A6"
                style="font-size: 18px; color: #4f4f4f; text-decoration: none"
                v-if="ChineseEn == 1 || ChineseEn == null"
                >A6系列</router-link
              >
              <router-link
                to="/A6"
                style="font-size: 18px; color: #4f4f4f; text-decoration: none"
                v-else
                >A6 Series</router-link
              >
            </el-menu-item>
            <el-menu-item index="/S6">
              <router-link
                to="/S6"
                style="font-size: 18px; color: #4f4f4f; text-decoration: none"
                v-if="ChineseEn == 1 || ChineseEn == null"
                >S6系列</router-link
              >
              <router-link
                to="/S6"
                style="font-size: 18px; color: #4f4f4f; text-decoration: none"
                v-else
                >S6 Series</router-link
              >
            </el-menu-item>
            <el-menu-item index="/V6">
              <router-link
                to="/V6"
                style="font-size: 18px; color: #4f4f4f; text-decoration: none"
                v-if="ChineseEn == 1 || ChineseEn == null"
                >V6系列</router-link
              >
              <router-link
                to="/V6"
                style="font-size: 18px; color: #4f4f4f; text-decoration: none"
                v-else
                >V6 Series</router-link
              >
            </el-menu-item>
            <el-menu-item index="/M6">
              <router-link
                to="/M6"
                style="font-size: 18px; color: #4f4f4f; text-decoration: none"
                v-if="ChineseEn == 1 || ChineseEn == null"
                >M6系列</router-link
              >
              <router-link
                to="/M6"
                style="font-size: 18px; color: #4f4f4f; text-decoration: none"
                v-else
                >M6 Series</router-link
              >
            </el-menu-item>
            <el-menu-item index="/AD_machine">
              <router-link
                to="/AD_machine"
                style="font-size: 18px; color: #4f4f4f; text-decoration: none"
                v-if="ChineseEn == 1 || ChineseEn == null"
                >广告机</router-link
              >
              <router-link
                to="/AD_machine"
                style="font-size: 18px; color: #4f4f4f; text-decoration: none"
                v-else
                >Advertising machine</router-link
              >
            </el-menu-item>
            <el-menu-item index="/LED">
              <router-link
                to="/LED"
                style="font-size: 18px; color: #4f4f4f; text-decoration: none"
                v-if="ChineseEn == 1 || ChineseEn == null"
                >LED显示屏</router-link
              >
              <router-link
                to="/LED"
                style="font-size: 18px; color: #4f4f4f; text-decoration: none"
                v-else
                >LED display screen</router-link
              >
            </el-menu-item>
          </el-submenu>
          <!-- <el-menu-item index="/S6"
            ><router-link to="/S6" v-if="ChineseEn == 1 || ChineseEn == null"
              >S6系列</router-link
            >
            <router-link to="/S6" v-else>S6 Series</router-link>
          </el-menu-item> -->
          <el-menu-item index="/meetingSurroundings"
            ><router-link
              to="/meetingSurroundings"
              v-if="ChineseEn == 1 || ChineseEn == null"
              class="menu1"
              >会议周边</router-link
            >
            <router-link to="/meetingSurroundings" v-else
              >Meeting Surroundings</router-link
            >
          </el-menu-item>
          <el-submenu index="4">
            <template slot="title" v-if="ChineseEn == 1 || ChineseEn == null"
              >应用场景</template
            >
            <template slot="title" v-else>Application Scenario</template>
            <el-menu-item index="/meetingTablet">
              <router-link
                to="/meetingTablet"
                style="font-size: 18px; color: #4f4f4f; text-decoration: none"
                v-if="ChineseEn == 1 || ChineseEn == null"
                >会议平板</router-link
              >
              <router-link
                to="/meetingTablet"
                style="font-size: 18px; color: #4f4f4f; text-decoration: none"
                v-else
                >Conference tablet</router-link
              >
            </el-menu-item>
            <el-menu-item index="/Etraining">
              <router-link
                to="/Etraining"
                style="font-size: 18px; color: #4f4f4f; text-decoration: none"
                v-if="ChineseEn == 1 || ChineseEn == null"
                >教育培训</router-link
              >
              <router-link
                to="/Etraining"
                style="font-size: 18px; color: #4f4f4f; text-decoration: none"
                v-else
                >Education and training</router-link
              >
            </el-menu-item>
            <el-menu-item index="/Happlication">
              <router-link
                to="/Happlication"
                style="font-size: 18px; color: #4f4f4f; text-decoration: none"
                v-if="ChineseEn == 1 || ChineseEn == null"
                >家庭应用</router-link
              >
              <router-link
                to="/Happlication"
                style="font-size: 18px; color: #4f4f4f; text-decoration: none"
                v-else
                >Home Applications</router-link
              >
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="/Splan"
            ><router-link
              to="/Splan"
              v-if="ChineseEn == 1 || ChineseEn == null"
              class="menu1"
              >解决方案</router-link
            >
            <router-link to="/Splan" v-else>Solution</router-link></el-menu-item
          >
          <el-menu-item index="/Scenter"
            ><router-link
              to="/Scenter"
              v-if="ChineseEn == 1 || ChineseEn == null"
              class="menu1"
              >服务中心</router-link
            >
            <router-link to="/Scenter" v-else>Service Center</router-link>
          </el-menu-item>
          <el-menu-item index="/Cus"
            ><router-link
              to="/Cus"
              v-if="ChineseEn == 1 || ChineseEn == null"
              class="menu1"
              >联系我们</router-link
            >
            <router-link to="/Cus" v-else>Contact Us</router-link>
          </el-menu-item>
        </el-menu>
        <div>
          <span
            class="listLogo"
            :style="{ display: isMenu2 }"
            @click="openList"
          >
            <b id="listLogo"></b>
          </span>
          <!-- 中文 -->
          <div class="nav1" :style="{ display: isMenu3 }">
            <span class="navclose" @click="closeMenu">
              <b></b>
            </span>
            <el-menu
              default-active="activeIndex"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
              background-color="#313131"
              v-if="ChineseEn == 1 || ChineseEn == null"
            >
              <el-menu-item index="/index">
                <i class="el-icon-menu"></i>
                <span slot="title" @click="closeMenu"
                  ><router-link to="/index">首页</router-link></span
                >
              </el-menu-item>
              <el-submenu index="2">
                <el-menu-item slot="title" style="padding-left: 0px">
                  <i class="el-icon-location"></i>
                  <span>产品展示</span>
                </el-menu-item>
                <el-menu-item-group>
                  <el-menu-item index="2-1" @click="closeMenu"
                    ><router-link to="/A6">A6 系列</router-link></el-menu-item
                  >
                  <el-menu-item index="2-2" @click="closeMenu"
                    ><router-link to="/S6">S6 系列</router-link></el-menu-item
                  >
                  <el-menu-item index="2-3" @click="closeMenu"
                    ><router-link to="/V6">V6 系列</router-link></el-menu-item
                  >
                  <el-menu-item index="2-4" @click="closeMenu"
                    ><router-link to="/M6">M6 系列</router-link></el-menu-item
                  >
                  <el-menu-item index="2-5" @click="closeMenu"
                    ><router-link to="/AD_machine">广告机</router-link></el-menu-item
                  >
                  <el-menu-item index="2-6" @click="closeMenu"
                    ><router-link to="/LED">LED显示屏</router-link></el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>

              <el-menu-item index="3">
                <i class="el-icon-document"></i>
                <span slot="title" @click="closeMenu"
                  ><router-link to="/meetingSurroundings"
                    >会议周边</router-link
                  ></span
                >
              </el-menu-item>
              <el-submenu index="4">
                <el-menu-item slot="title" style="padding-left: 0px">
                  <i class="el-icon-location"></i>
                  <span>应用场景</span>
                </el-menu-item>
                <el-menu-item-group>
                  <el-menu-item index="4-1" @click="closeMenu"
                    ><router-link to="/meetingTablet"
                      >会议平板</router-link
                    ></el-menu-item
                  >
                  <el-menu-item index="4-2" @click="closeMenu"
                    ><router-link to="/Etraining"
                      >教育培训</router-link
                    ></el-menu-item
                  >
                  <el-menu-item index="4-3" @click="closeMenu"
                    ><router-link to="/Happlication"
                      >家庭应用</router-link
                    ></el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>

              <el-menu-item index="5">
                <i class="el-icon-setting"></i>
                <span slot="title" @click="closeMenu"
                  ><router-link to="/Splan">解决方案</router-link></span
                >
              </el-menu-item>
              <el-menu-item index="6">
                <i class="el-icon-setting"></i>
                <span slot="title" @click="closeMenu"
                  ><router-link to="/Scenter">服务中心</router-link></span
                >
              </el-menu-item>
              <el-menu-item index="7">
                <i class="el-icon-setting"></i>
                <span slot="title" @click="closeMenu"
                  ><router-link to="/Cus">联系我们</router-link></span
                >
              </el-menu-item>
            </el-menu>
            <el-menu
              default-active="activeIndex"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
              background-color="#313131"
              v-else
            >
              <el-menu-item index="1">
                <i class="el-icon-menu"></i>
                <span slot="title" @click="closeMenu"
                  ><router-link to="/index">index</router-link></span
                >
              </el-menu-item>
              <el-submenu index="2">
                <el-menu-item slot="title" style="padding-left: 0px">
                  <i class="el-icon-location"></i>
                  <span>Products</span>
                </el-menu-item>
                <el-menu-item-group>
                  <el-menu-item index="2-1" @click="closeMenu"
                    ><router-link to="/A6">A6 Series</router-link></el-menu-item
                  >
                  <el-menu-item index="2-2" @click="closeMenu"
                    ><router-link to="/S6">S6 Series</router-link></el-menu-item
                  >
                  <el-menu-item index="2-3" @click="closeMenu"
                    ><router-link to="/V6">V6 Series</router-link></el-menu-item
                  >
                  <el-menu-item index="2-4" @click="closeMenu"
                    ><router-link to="/M6">M6 Series</router-link></el-menu-item
                  >
                  <el-menu-item index="2-5" @click="closeMenu"
                    ><router-link to="/AD_machine">Advertising machine</router-link></el-menu-item
                  >
                  <el-menu-item index="2-6" @click="closeMenu"
                    ><router-link to="/LED">LED display screen</router-link></el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>

              <el-menu-item index="3">
                <i class="el-icon-document"></i>
                <span slot="title" @click="closeMenu"
                  ><router-link to="/meetingSurroundings"
                    >Meeting Surroundings
                  </router-link></span
                >
              </el-menu-item>
              <el-submenu index="4">
                <el-menu-item slot="title" style="padding-left: 0px">
                  <i class="el-icon-location"></i>
                  <span>Application Scenario</span>
                </el-menu-item>
                <el-menu-item-group>
                  <el-menu-item index="4-1" @click="closeMenu"
                    ><router-link to="/meetingTablet"
                      >Conference tablet</router-link
                    ></el-menu-item
                  >
                  <el-menu-item index="4-2" @click="closeMenu"
                    ><router-link to="/Etraining"
                      >Education and training</router-link
                    ></el-menu-item
                  >
                  <el-menu-item index="4-3" @click="closeMenu"
                    ><router-link to="/Happlication"
                      >Home Applications</router-link
                    ></el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>

              <el-menu-item index="5">
                <i class="el-icon-setting"></i>
                <span slot="title" @click="closeMenu"
                  ><router-link to="/Splan">Solution </router-link></span
                >
              </el-menu-item>
              <el-menu-item index="6">
                <i class="el-icon-setting"></i>
                <span slot="title" @click="closeMenu"
                  ><router-link to="/Scenter"
                    >Service Center
                  </router-link></span
                >
              </el-menu-item>
              <el-menu-item index="7">
                <i class="el-icon-setting"></i>
                <span slot="title" @click="closeMenu"
                  ><router-link to="/Cus">Contact Us</router-link></span
                >
              </el-menu-item>
            </el-menu>
          </div>
        </div>
        <img src="../assets/viewdone/CHEN.png" alt="" @click="changeLan" />
      </div>
    </div>
    <!-- contain -->
    <div class="contain" style="height:100vh">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "viewdone",
  data() {
    return {
      activeIndex: null,
      isMenu1: "flex",
      isMenu2: "none",
      isMenu3: "none",
      screenWidth: document.body.clientWidth,
      index: 0, //点击导航栏切换
      ChineseEn: sessionStorage.getItem("chen"),
    };
  },
  mounted() {
    // this.$router.history.current.fullPath;
    console.log(this.ChineseEn, "mounted");
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
    // window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
  computed: {
    ...mapState(["isMobile","isMobileMenu"]),
    
  },
  watch: {
    screenWidth: {
      handler: function (val) {
        console.log(val);
        this.screenWidth = val;
        this.$nextTick(() => {
          if (this.screenWidth < 1215 && this.screenWidth >= 440) {
            console.log("PC端中");
            this.isMenu2 = "block";
            this.isMenu1 = "none";
            this.modifyState(false);
          } else if ((this.screenWidth >= 1215)) {
            console.log("PC端大");
            this.isMenu2 = "none";
            this.isMenu1 = "flex";
            this.modifyState(false);
          } else if (this.screenWidth < 440) {
            console.log("移动端");
            this.isMenu2 = "block";
            this.isMenu1 = "none";
            this.modifyState(true);
          }
          console.log("mapState——ismobile", this.isMobile);
        });
      },
      
      immediate: true,
      deep: true,
    },
    isMobileMenu: {
        handler:function(val) {
          console.log("1dddcdc——isModifyMenu",val);
          val?this.openList() : this.closeMenu()
        },
        immediate: true,
        deep: true,
      },
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      console.log(this.$router.currentRoute.path);
      // this.$router.go(0)
      // this.index = key
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    closeMenu() {
      this.isMenu3 = "none";
      this.isMenu2 = "block";
    },
    openList() {
      this.isMenu3 = "block";
      this.isMenu2 = "none";
    },
    changeLan() {
      if (sessionStorage.getItem("chen") == null) {
        this.ChineseEn = 2;
      } else if (sessionStorage.getItem("chen") == 1) {
        this.ChineseEn = 2;
      } else {
        this.ChineseEn = 1;
      }
      console.log("this.ChineseEn", this.ChineseEn);
      sessionStorage.setItem("chen", this.ChineseEn);
      console.log(sessionStorage.getItem("chen"), "first");
      this.$router.go(0);
      console.log(sessionStorage.getItem("chen"), "second");
    },
    ...mapMutations(["modifyState"]),
  },
};
</script>

<style lang="less" scoped>
.viewdone_div {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  a {
    text-decoration: none;
  }

  .nav {
    z-index: 1000;
    background: url(../assets/viewdone/nav.jpg);
    height: 8vh;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.8rem 0 0.5rem;
    box-shadow: 0px 2px 6px 1px #00000000;
    position: fixed;
    width: 100%;
    flex-wrap: nowrap;

    .menu1 {
      width: 6rem;
      text-align: center;
    }

    img {
      height: 2rem;
    }
    .right {
      display: flex;
      align-items: center;
      .el-menu--horizontal {
        background: transparent;
        border-bottom: none !important;
        .el-menu-item {
          height: 3.5rem;
          line-height: 3.6rem;
          padding: 0 !important;
          margin: 0 10px !important;
          a {
            color: #4f4f4f;
            font-size: 0.8rem;
            font-weight: 600;
            height: 3.5rem;
            display: inline-block;
          }
        }

        /deep/.el-submenu {
          .el-submenu__title {
            height: 3.2rem;
            line-height: 3.5rem;
            color: #4f4f4f !important;
            font-size: 0.8rem !important;
            font-weight: 600;
            text-align: center;
            border-bottom: none;
          }
        }

        .is-active {
          border-bottom: 4px solid #419df4;
        }
      }
      img {
        width: 2rem;
        height: 2rem;
        cursor: pointer;
      }
      .listLogo {
        position: absolute;
        right: 30px;
        top: 0;
        height: 10%;
        background: url(../assets/line3.jpg) left top no-repeat;

        b {
          background: url(../assets/list.png) no-repeat;
          width: 3rem;
          height: 2.5rem;
          display: block;
          margin: 1rem 1.8rem 0 0.8rem;
          cursor: pointer;
        }
      }
      .nav1 {
        float: none;
        margin: 0;
        position: absolute;
        top: 0;
        width: 100%;
        right: 0;
        background: #313131;
        height: 100vh;
        overflow: scroll;

        .navclose {
          overflow: hidden;
          clear: both;
          width: 100%;
          background: #272727;
          line-height: 42px;
          height: 42px;
          display: block;
          b {
            float: right;
            background: url(../assets/close.png) no-repeat;
            width: 1.3rem;
            height: 1.3rem;
            background-size: contain;
            margin: 10px 20px 0 15px;
            cursor: pointer;
          }
        }
        .el-menu /deep/ .el-menu-item a,
        .el-menu /deep/ .el-menu-item span {
          color: white !important;
          height: 100%;
          display: inline-block;
          width: 100%;
        }

        .el-submenu /deep/ .el-submenu__icon-arrow {
          right: 5%;
          margin-top: -10px;
          font-weight: 800;
          font-size: 1.2rem;
        }
      }
    }
  }

  // .contain {
  //   // width: 100vw;
  //   height: 91vh;
  //   background: url(../assets/viewdone/home.jpg) no-repeat;
  //   background-size: cover;
  //   // overflow: hidden;

  //   .intro {
  //     position: absolute;
  //     top: 35.3%;
  //     left: 51%;
  //     h4 {
  //       font-size: 34px;
  //       color: #4f4f4f;
  //       font-weight: 800;
  //     }
  //     h3 {
  //       font-size: 45px;
  //       font-weight: 800;
  //       color: #101010;
  //       letter-spacing: 11px;
  //     }
  //     div {
  //       display: flex;
  //       align-items: baseline;

  //       h5 {
  //         font-size: 28px;
  //         color: #4f4f4f;
  //       }
  //       h4 {
  //         margin: 0 8px;
  //         font-weight: normal;
  //         color: #bbbbbb;
  //       }
  //     }
  //     button {
  //       border: none;
  //       background: #cecece;
  //       padding: 5px 10px;
  //       width: 182px;
  //       height: 50px;
  //       border-radius: 12px;
  //       font-size: 25px;
  //       margin-top: 10px;
  //       img {
  //           width: 24px;
  //           height: 24px;
  //       }
  //     }
  //   }
  // }
}
</style>
