import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from "../views/HomeView.vue";
import viewdone from "../views/viewdone.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index",
  },

  {
    path: "/",
    name: "viewdone",
    component: viewdone,
    children:[
      {
        path: "index",
        name: "首页",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/index.vue"),
      },
      {
        path: "A6",
        name: "A6系列",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/A6.vue"),
      },
      {
        path: "S6",
        name: "S6系列",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/S6.vue"),
      },
      {
        path: "V6",
        name: "V6系列",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/V6.vue"),
      },
      {
        path: "M6",
        name: "M6系列",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/M6.vue"),
      },
      {
        path: "AD_machine",
        name: "广告机",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/AD_machine.vue"),
      },
      {
        path: "LED",
        name: "LED显示屏",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/LED.vue"),
      },
      {
        //会议周边
        path: "meetingSurroundings",
        name: "会议周边",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Csurroundings.vue"),
      },
      {
        // 会议平板
        path: "meetingTablet",
        name: "会议平板",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Cmachine.vue"),
      },
      {
        // 教育培训
        path: "Etraining",
        name: "教育培训",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Etraining.vue"),
      },
      {
        // 家庭应用
        path: "Happlication",
        name: "家庭应用",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Happlication.vue"),
      },
      {
        // 解决方案
        path: "Splan",
        name: "解决方案",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Splan.vue"),
      },
      {
        // 服务中心
        path: "Scenter",
        name: "服务中心",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Scenter.vue"),
      },
      {
        // 联系我们
        path: "Cus",
        name: "联系我们",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Cus.vue"),
      },
    ]
  },
  {
    path: "/qdd2",
    name: "qdd",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/qdd2.vue"),
  },
  {
    path: "/bookDemo",
    name: "bookDemo",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/bookDemo.vue"),
  },
  // {
  //   path: "/conferenceMachine",
  //   name: "conferenceMachine",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/conferenceMachine.vue"),
  // },
  // {
  //   path: "/wirelessScreen",
  //   name: "wirelessScreen",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/wirelessScreen.vue"),
  // },
  // {
  //   path: "/mobileStand",
  //   name: "mobileStand",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/mobileStand.vue"),
  // },
  
  
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  
scrollBehavior (to, from, savedPosition) {
  // return 期望滚动到哪个的位置
  return { x: 0, y: 0 }
  }
  
});

export default router;
